import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import IntroPage from "./components/introPage";
import NotFound from "./components/404";

import PrivacyPolicy from "./views/policy/privacy-policy";
import RefundPolicy from "./views/policy/refund";
import TermsConditions from "./views/policy/terms";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<IntroPage />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
