import React, { useMemo } from "react";

import { useQuery } from "@tanstack/react-query";
import he from "he";
import { Col, Container, Row, Spinner } from "reactstrap";
import Swal from "sweetalert2";

import { getDetails } from "../apis/getDetails";
import uploadedPdfIcon from "../assets/iconUploadedPdf.svg";
import ErrorImage from "../assets/icons/error.svg";
import placeholderImage from "../assets/logo/main-logo.png";
import Footer from "../components/footer";
import Nav from "../components/navbar";
import { ConvertFirstLatterToCapital } from "../utility";

import "animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "../App.css";
import { useLocation } from "react-router-dom";

export const CenteredBox = ({ children, height }) => {
  return (
    <div
      style={{ height: height }}
      className="d-flex align-items-center justify-content-center"
    >
      {children}
    </div>
  );
};
const IntroPage = () => {
  const subDomain = window.location.host.replace(
    process.env.REACT_APP_SUBDOMAIN_REPLACE_VALUE,
    ""
  );

  const { data, isFetching } = useQuery({
    queryKey: ["details"],
    queryFn: () => getDetails(subDomain),
  });

  const trustDetails = useMemo(() => data?.data?.result ?? null, [data]);
  return (
    <div className="overflow-hidden">
      {isFetching ? (
        <CenteredBox height="100dvh">
          <Spinner
            style={{ height: "3rem ", width: "3rem", color: "#ff8744" }}
          />
        </CenteredBox>
      ) : !trustDetails ? (
        <CenteredBox height="100dvh">
          <div className="w-50 text-center">
            <img src={ErrorImage} className="technical-error-image" alt="" />
            <p className="technical-error-text">
              There are some technical error, please contact to{" "}
              <a
                href="mailto:support@apnadharm.com"
                style={{ color: "#ff8744" }}
              >
                support@apnadharm.com
              </a>
            </p>
          </div>
        </CenteredBox>
      ) : (
        <>
          <Nav />

          <div className="main-layout hide-scrollbar">
            <Container>
              <div className="bg_background_intro">
                <img
                  src={
                    trustDetails?.profilePhoto?.length
                      ? trustDetails?.profilePhoto
                      : placeholderImage
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    objectPosition:
                      trustDetails?.profilePhoto?.length && "top center",
                    objectFit: trustDetails?.profilePhoto?.length && "cover",
                  }}
                  alt="Temple Profile"
                />
              </div>
              <div>
                <div className="details_title">
                  {ConvertFirstLatterToCapital(trustDetails?.name ?? "")}
                </div>
                <div className="row align-items-center">
                  <Col lg={12} xs={8}>
                    <div className="temple_location">
                      {ConvertFirstLatterToCapital(
                        trustDetails?.location ?? ""
                      )}
                    </div>
                  </Col>
                </div>
                <div className="div_size pb-0">
                  <div className="heading_div">About Trust</div>
                  <div className="bg_sub_title">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: he.decode(trustDetails?.about ?? ""),
                      }}
                    />
                  </div>
                </div>
                {trustDetails?.images?.length !== 0 ? (
                  <div className="div_size pb-0">
                    <div className="heading_div">Images</div>
                    <Row className="flex-nowrap overflow-auto imagesScroller">
                      {trustDetails?.images?.map((item, idx) => {
                        return (
                          <Col xs={6} sm={3} key={idx}>
                            <div className="trust_img mt-4 mb-2">
                              <img
                                src={item?.presignedUrl}
                                alt="Trust Images"
                                style={{ cursor: "zoom-in" }}
                                onClick={() =>
                                  Swal.fire({
                                    imageUrl: item?.presignedUrl,
                                    // "https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg",
                                    padding: 0,
                                    imageAlt: "Trust Images",
                                    imageHeight: 350,
                                    imageWidth: 1000,
                                    customClass: {
                                      image: "popup-trust-images", // Add a custom class to the image container
                                    },
                                    showConfirmButton: false,
                                    showCloseButton: true,
                                    showClass: {
                                      popup:
                                        "animate__animated animate__zoomIn animate__faster",
                                    },
                                    hideClass: {
                                      popup:
                                        "animate__animated animate__zoomOut animate__faster",
                                    },
                                  })
                                }
                              />
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                ) : (
                  ""
                )}
                {/* trust Documents */}
                {trustDetails?.documents?.length !== 0 ? (
                  <div className="div_size pb-0">
                    <div className="heading_div">Documents</div>
                    <Row className="">
                      {trustDetails?.documents?.map((item, idx) => {
                        return (
                          <Col
                            lg={2}
                            md={4}
                            sm={6}
                            key={idx}
                            className="text-center"
                          >
                            <a
                              href={item?.presignedUrl}
                              target="_blank"
                              rel="noreferrer"
                              className="text-decoration-none"
                            >
                              <div className="">
                                <img src={uploadedPdfIcon} width={50} alt="" />
                              </div>
                              <div className="docFileName mt-2">
                                {item?.name}
                              </div>
                            </a>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                ) : (
                  ""
                )}
                {trustDetails?.facilities?.length !== 0 ? (
                  <div className="div_size">
                    <div className="heading_div">Trust Facilities</div>
                    <Row>
                      {trustDetails?.facilities?.map((item, idx) => {
                        return (
                          <Col lg={3} md={4} sm={6} key={idx}>
                            <div className="trust_img">
                              <img src={item?.image} alt="" />
                            </div>
                            <div className="py-1">
                              <div className="temple_name">
                                {ConvertFirstLatterToCapital(item?.name ?? "")}
                              </div>
                              {item?.startTime && item?.endTime && (
                                <div className="temple_time">
                                  Timings : {item?.startTime} to {item?.endTime}
                                </div>
                              )}
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Container>

            <Footer />
          </div>
        </>
      )}
    </div>
  );
};

export default IntroPage;
