import axios from "axios";
import { toast } from "react-toastify";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getDetails = async (payload) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}public/sub-domain-details/${payload}`,
      { timeout: 20000 }
    );
    return response?.data;
  } catch (error) {
    if (error.code === "ECONNABORTED") {
      toast.error("Please check your internet connection.", {
        toastId: "internet Error ",
      });
    } else {
      toast.error("Something went wrong", {
        toastId: "APi error ",
      });
    }
    throw error;
  }
};
