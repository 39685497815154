import { Container, Navbar, NavbarBrand } from "reactstrap";
import logo from "../assets/logo/main-logo.png";

const Nav = () => {
  return (
    <div>
      <Navbar>
        <Container>
          <NavbarBrand href="/">
            <img
              alt="logo"
              src={logo}
              style={{
                height: 60,
                objectFi0: "contain",
                width: 170,
              }}
            />
          </NavbarBrand>
        </Container>
      </Navbar>
    </div>
  );
};

export default Nav;
